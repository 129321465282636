






























































































import { API } from "@/shared/api";
import { fichajes_empleado } from "@/shared/dtos/fichajes_empleado";
import { ssmHttpService } from "@/shared/services/http-service";
import { MessageService } from "@/shared/services/message-service";
import tipos_fichajeModule from "@/store/modules/tipos_fichaje-module";
import { UtilsString } from "@/utils/utils-string";
import { Component, Ref, Vue } from "vue-property-decorator";
import DxTextBox from "devextreme-vue/text-box";
import { DxValidationGroup } from "devextreme-vue/validation-group";

import {
  DxValidator,
  DxRequiredRule,
  DxAsyncRule,
} from "devextreme-vue/validator";
import { UtilsBoolean } from "@/utils/utils-boolean";
import { BaseDto } from "@/shared/dtos/base-dto";
import { UsuarioAllDto } from "@/shared/dtos/usuariosAllDto";
import { centro_trabajo } from "@/shared/dtos/centro_trabajo";
import { datos_empresa } from "@/shared/dtos/datos_empresa";
import EtiquetasCombo from "@/components/EtiquetaCombo/EtiquetasCombo.vue";
@Component({
  components: {
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxAsyncRule,
    DxValidationGroup,
    EtiquetasCombo,
  },
  $_veeValidate: { validator: "new" },
})
export default class fichaje_formulario extends Vue {
  private dni: string = "";
  private name_img = "name_img";
  private name_id_empresa = "name_id_empresa";
  private name_text_form = "name_text_form";
  private name_dni_form = "name_dni_form";
  private text_form = "";
  private img_form = "/assets/images/logo_cliente.png";
  private id_fichaje = 1;
  private id_centro_trabajo = 1;
  private ultima_validacion_dni = false;
  private usuario: any = null;
  private datos_empresa: datos_empresa = new datos_empresa();
  private nombre_campo = "Centro de trabajo";
  private centros_trabajo: centro_trabajo[] = [];
  private nuevo_centro: string = "";
  private shw_form: boolean = false;
  private location: LocationData = { latitude: 0, longitude: 0 };

  @Ref("customValidator") readonly validacion!: DxValidationGroup;

  public created() {
    tipos_fichajeModule.gettipos_fichajes_public();
    if (
      !UtilsString.IsNullOrWhiteSpace(
        UtilsString.ValueOf(window.localStorage.getItem(this.name_id_empresa))
      )
    ) {
      ssmHttpService
        .get(
          API.public +
            "/documents_cliente/" +
            UtilsString.ValueOf(
              window.localStorage.getItem(this.name_id_empresa)
            ),
          null,
          true,
          false,
          true
        )
        .then((x) => {
          window.localStorage.setItem(
            this.name_img,
            API.webApiBasedatos_empresa + x.name_file
          );
          window.localStorage.setItem(this.name_text_form, x.name_empresa);
          this.img_form = API.webApiBasedatos_empresa + x.name_file;
          this.text_form = x.name_empresa;
        });
    }

    if (
      !UtilsString.IsNullOrWhiteSpace(
        UtilsString.ValueOf(window.localStorage.getItem(this.name_img))
      )
    ) {
      this.img_form = UtilsString.ValueOf(
        window.localStorage.getItem(this.name_img)
      );
    }

    if (
      !UtilsString.IsNullOrWhiteSpace(
        UtilsString.ValueOf(window.localStorage.getItem(this.name_text_form))
      )
    ) {
      this.text_form = UtilsString.ValueOf(
        window.localStorage.getItem(this.name_text_form)
      );
    }
    //parametros de fichaje.

    try {
      if (
        !UtilsString.IsNullOrWhiteSpace(
          UtilsString.ValueOf(this.$route.query.dni)
        )
      ) {
        this.dni = UtilsString.ValueOf(this.$route.query.dni);
      }

      if (
        !UtilsString.IsNullOrWhiteSpace(
          UtilsString.ValueOf(this.$route.query.validar)
        )
      ) {
        if (UtilsBoolean.Anytoboolean(this.$route.query.validar)) {
          (async () => {
            setTimeout(() => {
              this.aceptar();
            }, 60);
          })();
        }
      }
    } catch {}
  }

  mounted() {
    // Ejemplo de cómo usar la función getLocation
    this.getLocation()
      .then((location) => {
        this.location = location;
      })
      .catch((error) => {
        console.error("Error obtaining location:", error.error);
      });
  }
  get tipos_fichaje() {
    return tipos_fichajeModule.tipos_fichajes;
  }

  ChangeValue(item: number) {
    if (item === -1) {
      //Definimos un centro nuevo
      this.shw_form = true;
    }
  }

  asyncValidation(params: any) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.dni_nie(params.value));
      }, 1);
    });
  }
  crear() {
    var centro = new centro_trabajo();
    centro.nombre = this.nuevo_centro;
    centro.id_cliente = this.centros_trabajo.find(
      (x) => x.id === this.id_centro_trabajo
    )!.id_cliente;
    centro.notas = this.dni;

    ssmHttpService
      .post(API.public + "/centro_trabajo/", centro.toJson(), true, false, true)
      .then((x) => {
        this.centros_trabajo = x.centros;
        this.id_centro_trabajo = x.centro_seleccionado.id;
        this.shw_form = false;
        MessageService.toastsuccesful(this.nombre_campo + " creado");
      })
      .catch((x) => {
        MessageService.toast(
          "Error al crear " + this.nombre_campo,
          MessageService.TypeError
        );
      });
  }

  public async dni_nie(value: any) {
    let validChars = "TRWAGMYFPDXBNJZSQVHLCKET";
    let nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
    let nieRexp = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
    let str = value.toString().toUpperCase();

    if (!nifRexp.test(str) && !nieRexp.test(str)) {
      this.ultima_validacion_dni = false;
      return this.ultima_validacion_dni;
    }

    let nie = str
      .replace(/^[X]/, "0")
      .replace(/^[Y]/, "1")
      .replace(/^[Z]/, "2");

    let letter = str.substr(-1);
    let charIndex = parseInt(nie.substr(0, 8)) % 23;

    if (validChars.charAt(charIndex) === letter) {
      var response = await ssmHttpService
        .get(API.public + "/validar/" + this.dni, null, false, false, false)
        .then((x: any) => {
          return x;
        });
      this.ultima_validacion_dni = response.encontrado;
      if (this.ultima_validacion_dni) {
        this.usuario = response.usuario;
        this.datos_empresa = response.datos_empresa;
        this.centros_trabajo = response.centros;
        for (let index = 0; index < this.centros_trabajo.length; index++) {
          const element = this.centros_trabajo[index];
          if (this.id_centro_trabajo === 1) {
            this.id_centro_trabajo = element.id;
          }
          break;
        }
        this.nombre_campo = this.datos_empresa.nombre_donde_fichan;
      }
      return this.ultima_validacion_dni;
    }
    this.ultima_validacion_dni = false;
    return this.ultima_validacion_dni;
  }
  public validar_los_datos() {
    //@ts-ignore
    return this.validacion.instance!.validate().isValid;
  }
  /**
   * aceptar
   */
  public aceptar() {
    if (this.validar_los_datos()) {
      this.$validator.validateAll().then((allOk) => {
        if (allOk) {
          // Ejemplo de cómo usar la función getLocation
          this.getLocation()
            .then((location) => {
              this.location = location;
            })
            .catch((error) => {
              console.error("Error obtaining location:", error.error);
            });
          var loc =
            this.location.latitude.toString() +
            "," +
            this.location.longitude.toString();

          ssmHttpService
            .get(
              API.public + "/EsFichajeEntrada/" + this.dni,
              null,
              true,
              false,
              true
            )
            .then((x) =>
              MessageService.confirm("Fichaje", x, (res) => {
                if (res) {
                  ssmHttpService
                    .get(
                      API.public +
                        "/Fichar/" +
                        this.dni +
                        "/" +
                        this.id_fichaje +
                        "/" +
                        this.id_centro_trabajo +
                        "/" +
                        loc,
                      null,
                      true,
                      false,
                      true
                    )
                    .then((x) => {
                      MessageService.alertRespose(
                        "Fichaje",
                        "Ha fichado correctamente",
                        () => {}
                      );
                      window.localStorage.setItem(
                        this.name_id_empresa,
                        x.id_cliente
                      );
                      this.dni = "";
                      this.$validator.reset();
                    });
                } else {
                  MessageService.alertRespose(
                    "Fichaje",
                    "Ha cancelado el fichaje",
                    () => {},
                    MessageService.TypeError
                  );
                }
              })
            )
            .catch(function(e) {
              try {
                var k = new BaseDto(e);
                //@ts-ignore
                var mensaje = UtilsString.ValueOf(k.response.data.message);
                if (mensaje.includes("no ha sido encontrado")) {
                  MessageService.alertRespose(
                    "Revise su DNI",
                    mensaje,
                    () => {},
                    MessageService.TypeError
                  );
                }
              } catch (error) {
                MessageService.alertRespose(
                  "Fichaje",
                  "Ha habido un error al fichar",
                  () => {},
                  MessageService.TypeError
                );
              }
            });
        }
      });
    }
  }

  /*
   */

  getLocation(): Promise<LocationData> {
    return new Promise((resolve, reject) => {
      // Verificar si el navegador soporta geolocalización
      if (!navigator.geolocation) {
        reject({ error: "Geolocation is not supported by your browser" });
        return;
      }

      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Exito al obtener la localización
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          // Manejar errores
          switch (error.code) {
            case error.PERMISSION_DENIED:
              reject({ error: "User denied the request for Geolocation." });
              break;
            case error.POSITION_UNAVAILABLE:
              reject({ error: "Location information is unavailable." });
              break;
            case error.TIMEOUT:
              reject({ error: "The request to get user location timed out." });
              break;
            default:
              reject({ error: "An unknown error occurred." });
              break;
          }
        }
      );
    });
  }
}
interface LocationData {
  latitude: number;
  longitude: number;
  error?: string;
}
